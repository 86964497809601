.vocabCollectionCard
  min-width: 250px
  max-width: 400px

  .imageContainer
    height: 150px
    overflow: hidden

  ion-grid
    --ion-grid-column-padding: 0
    padding: 10px 0 0

    .extraButtons
      text-align: right

    .buttonClear
      --color: #c6cede
      --background-hover: unset
      --background-focused: unset
      --border-radius: 50%
      --padding-top: 0
      --padding-end: 0
      --padding-bottom: 0
      --padding-start: 0

      height: 36px
      width: 36px
      margin: 0

@media (min-width: 450px) and (max-width: 593px)
  .vocabCollectionCard
    max-width: unset
