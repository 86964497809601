.settingsTab {
  ion-item {
    --transition: none;
  }

  .settingsButton {
    width: 52px;
    height: 32px;
  }
}
