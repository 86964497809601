.image
  margin: 10px

  img
    border-radius: 10px

.detailsList
  margin-top: 10px
  margin-bottom: 15px

.buttonsGrid
  .button
    width: 100%

.indexCardsGrid
  h2
    margin-left: 10px

  .item
    --background: var(--ion-color-light)
    border-radius: 10px
    text-align: center

@media screen and (min-width: 768px)
  .image
    display: none
