.advancedSettingsModal
  input
    // Muss hier stehen!
    // Autoformat rückt sonst nicht richtig ein
    // und man kann ion-input nicht nutzen...

  ion-input
    text-align: right

  ion-item 
    --transition: none
