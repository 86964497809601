#inputCard
  margin-bottom: 50px

#notFound
  text-align: center

.column
  max-width: 400px

.noCollections
  text-align: center
  position: absolute
  left: 0
  right: 0
  top: 50%
  transform: translateY(-50%)

  strong
    font-size: 20px
    line-height: 26px

  p
    font-size: 16px
    line-height: 22px
    color: #8c8c8c
    margin: 0

  .click
    cursor: pointer
