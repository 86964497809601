.dictionaryEntryCard
  .header
    padding-bottom: 5px

  .content
    padding-bottom: 0

  .meanings
    margin-top: 20px
    margin-bottom: 10px

  ion-grid
    --ion-grid-column-padding: 0
    padding: 10px 0 0
    margin-bottom: 10px

    .buttons
      text-align: right

      .buttonClear
        --color: #c6cede
        --background-hover: unset
        --background-focused: unset
        --border-radius: 50%
        --padding-top: 0
        --padding-end: 0
        --padding-bottom: 0
        --padding-start: 0

        height: 36px
        width: 36px
        margin: 0
