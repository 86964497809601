.learnPage
  small
    font-size: 50%
    marign-left: 5px

  .meanings
    margin-top: 5px !important

  .form
    display: none

  ion-button
    height: 3.1em

    &::part(native)
      line-height: 120%
