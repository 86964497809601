.learnGrid
  padding: 16px

  .learnCol
    margin: 10px
    padding: 0

    &#heatmapCol
      max-width: 760px

  .dateCard
    min-width: 310px
    margin: 0

    &.danger
      border-left-width: 3px
      border-left-style: solid
      border-color: var(--ion-color-danger)

    &.success
      border-left-width: 3px
      border-left-style: solid
      border-color: var(--ion-color-success)

  .statsCard
    min-width: 310px
    margin: 0

    p.marginBottom
      margin-bottom: 10px

.actionFooter
  position: absolute
  bottom: 5px

.click
  cursor: pointer
