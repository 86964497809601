.editorList
  margin-top: 10px
  margin-bottom: 15px
  background: var(--ion-background-color)

  .saveButton
    margin-top: 25px

#lookUp
  cursor: pointer
